import React from 'react'
import Button from './button'
import * as style from '../styles/theme/puppyCard.module.scss'

const PuppyCard = (props) => (
  <article className={style.puppyCard}>
    <a name={props.name.replace(' ', '-').toLowerCase()} />
    <h2>"{props.name}"</h2>
    <div className={style.content}>
      {props.image}
      <div className={style.info}>
        <table>
          <tbody>
            <tr>
              <th scope="row" align="start">
                Breed:
              </th>
              <td>{props.breed}</td>
            </tr>
            <tr>
              <th scope="row" align="start">
                DOB:
              </th>
              <td>{props.dob}</td>
            </tr>
            <tr>
              <th scope="row" align="start">
                Sex:
              </th>
              <td>{props.sex}</td>
            </tr>
            <tr>
              <th scope="row" align="start">
                Take me home:
              </th>
              <td>{props.available}</td>
            </tr>
            <tr>
              <th scope="row" align="start">
                Price:
              </th>
              <td>{props.price}</td>
            </tr>
            <tr>
              <th scope="row" align="start">
                About:
              </th>
              <td>{props.children}</td>
            </tr>
          </tbody>
        </table>
        {/* <Button small link={ `/puppies/${props.name.toLowerCase()}` }>{ `More about ${ props.name}!` }</Button> */}
        <br />
        <Button
          small
          link={`mailto:vanessa@wilderborngsd.life?subject=Enquiry about ${props.name}`}
        >{`Enquire about ${props.name}!`}</Button>
      </div>
    </div>
  </article>
)

export default PuppyCard
