import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Seo from '../components/seo'
import Layout from '../components/layout'
import PuppyCard from '../components/puppyCard'

const Puppies = () => (
  <Layout>
    <Seo title="Puppies" />
    <article>
      <h1>Puppies</h1>
      <h2>Young puppies</h2>
      <p>We currently have the following young puppies available.</p>
      <PuppyCard
        name="Purebred GSD litter"
        dob="2 June 2023"
        sex="3 Male, 8 Female"
        breed="German Shepherd Dog"
        available="11 August 2023"
        price="$1600"
      >
        We have a gorgeous litter of 11 GSD puppies born June 2nd, 2023. There
        are 3 males and 8 females with a mix of coat lengths and colours. Please{' '}
        <Link to="contact">contact us</Link> for more information.
      </PuppyCard>
      <PuppyCard
        name="Winnie"
        dob="10 Dec 2022"
        sex="Female"
        breed="Sheprador"
        available="Now"
        price="$1400"
      >
        I'm an adorable little girl that will melt your heart! I've got a lovely
        glossy black coat, one cute flopsy ear and am very friendly, bright and
        trainable.
      </PuppyCard>
      <h2>Older Pups</h2>
      <p>
        These are beautiful older pups (approx 18 months old) we have available
        that have a lot of the hard work done. While almost fully mature they
        are still very much playful puppies with more growing and filling out to
        do and are ready to settle into their perfect forever homes!
      </p>
      <PuppyCard
        name="Midnight"
        dob="01 Jan 2022"
        sex="Female"
        breed="German Shepherd Dog"
        available="Now"
        price="$1400"
      >
        I have a beautiful, shiny, all-black coat, an athletic physique and I am
        very alert and intelligent. I would love to find a home with an active
        family with kids.
      </PuppyCard>
      <PuppyCard
        name="Frosty"
        dob="01 Jan 2022"
        sex="Female"
        breed="German Shepherd Dog"
        available="Now"
        price="$1400"
      >
        Much like my sister "Midnight" I have a beautiful, shiny, all-black coat
        but have a touch of white "frost" on a few of my toes! I also have an
        athletic physique and I am very alert and intelligent. I would also love
        to find a home with an active family with kids.
      </PuppyCard>
      {/* <PuppyCard
        name="Elvis"
        dob="01 Jan 2022"
        sex="Male"
        breed="German Shepherd Dog"
        available="Now"
        price="$1800"
      >
        Beautiful glossy bi-colour coat, love being around people, very loving
        and intelligent.
      </PuppyCard> */}
      <PuppyCard
        name="Dakota"
        dob="01 Jan 2022"
        sex="Male"
        breed="German Shepherd Dog"
        available="Now"
        price="$1200"
      >
        Very handsome and lovely boy. I love to be with my people and like most
        German Shepherds I am very loving and loyal and will make a great family
        pet.
      </PuppyCard>
      {/* <PuppyCard
        name="Bruce"
        dob="01 Jan 2022"
        sex="Male"
        breed="German Shepherd Dog"
        available="Now"
        price="$1800"
      >
        This is some info about me and why I'm so awesome!
      </PuppyCard> */}
      {/* <PuppyCard
        image={
          <StaticImage
            src="../images/chase.jpg"
            alt="Puppy named Chase"
            layout="fixed"
            height="200"
          />
        }
        name="Chase"
        dob="01 Jan 2022"
        sex="Male"
        breed="German Shepherd Dog"
        available="Now"
        price="$1800"
      >
        I'm a beautiful blanket black and light-tan boy, lovely glossy coat,
        great temperament and intelligence.
      </PuppyCard> */}
      <PuppyCard
        image={
          <StaticImage
            src="../images/littlered.jpg"
            alt="Puppy named Little Red"
            layout="fixed"
            height="200"
          />
        }
        name="Little Red"
        dob="01 Jan 2022"
        sex="Female"
        breed="German Shepherd Dog"
        available="Now"
        price="$1200"
      >
        I'm a pocket rocket! I might be smaller but I make up for it with
        playfulness and agility! Would love a home where I can run and play, but
        I also love to snuggle!
      </PuppyCard>
      {/* <PuppyCard
        image={ <StaticImage
          src="../images/littlered.jpg"
          alt="Puppy named Bianca" 
          layout="fixed"
          height="200"
        /> }
        name="Bianca"
        dob="01 Jan 2022"
        sex="Female"
        breed="German Shepherd Dog"
        available="Now"
        price="$1800"
      >
        This is some info about me and why I'm so awesome!
      </PuppyCard> */}
      {/* <PuppyCard
        image={ <StaticImage
          src="../images/littlered.jpg"
          alt="Puppy named Bluebell" 
          layout="fixed"
          height="200"
        /> }
        name="Bluebell"
        dob="01 Jan 2022"
        sex="Female"
        breed="German Shepherd Dog"
        available="Now"
        price="$1800"
      >
        This is some info about me and why I'm so awesome!
      </PuppyCard> */}
      <p>
        All puppies come with a puppy pack (incl. food starter pack) and come
        wormed, vaccinated and microchipped and receive a thorough veterinary
        health check.
      </p>
      <p>
        If you are interested in adopting one of our beauties please{' '}
        <Link to="contact">Contact us</Link>.
      </p>
    </article>
  </Layout>
)

export default Puppies
